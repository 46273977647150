import type { Config } from "tailwindcss";
import tailwindCssAnimate from "tailwindcss-animate";
import tailwindTypography from "@tailwindcss/typography";

export const navbarHeight = 80; //px
export const footerHeight = 80; //px
export const breadCrumbHeight = 80; //px

export default {
  darkMode: ["class"],
  content: ["./app/**/{**,.client,.server}/**/*.{js,jsx,ts,tsx}"],
  theme: {
    extend: {
      typography: {
        DEFAULT: {
          css: {
            "--tw-prose-bold": "inherit", // Replace with your desired color
          },
        },
        quoteless: {
          css: {
            "blockquote p:first-of-type::before": { content: `'"'` },
            "blockquote p:first-of-type::after": { content: `'"'` },
            "blockquote p:last-of-type::after": { content: "none" },
            "blockquote p:last-of-type": {
              fontWeight: "normal",
              fontSize: "smaller",
            },
          },
        },
      },
      aria: {
        current: 'current="page"',
      },
      spacing: {
        "navbar-height": `${navbarHeight}px`,
        "footer-height": `${footerHeight}px`,
        "breadcrumb-height": `${breadCrumbHeight}px`,
      },
      fontFamily: {
        sans: [
          'Inter"',
          "ui-sans-serif",
          "system-ui",
          "sans-serif",
          'Apple Color Emoji"',
          'Segoe UI Emoji"',
          'Segoe UI Symbol"',
          'Noto Color Emoji"',
        ],
        gabarito: ["Gabarito", "sans-serif"],
        cabin: ["Cabin", "sans-serif"],
      },
      borderRadius: {
        lg: "var(--radius)",
        md: "calc(var(--radius) - 2px)",
        sm: "calc(var(--radius) - 4px)",
      },
      colors: {
        background: "hsl(var(--background))",
        foreground: "hsl(var(--foreground))",
        card: {
          DEFAULT: "hsl(var(--card))",
          foreground: "hsl(var(--card-foreground))",
        },
        popover: {
          DEFAULT: "hsl(var(--popover))",
          foreground: "hsl(var(--popover-foreground))",
        },
        primary: {
          DEFAULT: "hsl(var(--primary))",
          foreground: "hsl(var(--primary-foreground))",
        },
        secondary: {
          DEFAULT: "hsl(var(--secondary))",
          foreground: "hsl(var(--secondary-foreground))",
        },
        muted: {
          DEFAULT: "hsl(var(--muted))",
          foreground: "hsl(var(--muted-foreground))",
        },
        accent: {
          DEFAULT: "hsl(var(--accent))",
          foreground: "hsl(var(--accent-foreground))",
        },
        destructive: {
          DEFAULT: "hsl(var(--destructive))",
          foreground: "hsl(var(--destructive-foreground))",
        },
        border: "hsl(var(--border))",
        input: "hsl(var(--input))",
        ring: "hsl(var(--ring))",
        chart: {
          "1": "hsl(var(--chart-1))",
          "2": "hsl(var(--chart-2))",
          "3": "hsl(var(--chart-3))",
          "4": "hsl(var(--chart-4))",
          "5": "hsl(var(--chart-5))",
        },
      },
    },
  },
  plugins: [tailwindCssAnimate, tailwindTypography],
} satisfies Config;
